<template>
  <ion-page id="community">
    <ion-content :fullscreen="true" @click="showRecipientList = false">
      <topbar :class="{ 'gradient30 fixed': !$store.state.isPC, 'top-bar-white': $store.state.isPC }" :bigtitle="false" :showBack="true" :showClose="false" :share="false" :shareData="shareData"></topbar>
      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="title-PC2" v-if="$store.state.isPC">Community Benefit Program</div>

          <div class="content community-content max-600">
            <div class="community-img">
              <img src="../assets/community.png" :style="$store.state.isPC ? 'border-radius: 20px 20px 0 0' : ''" />
            </div>
            <div class="donation-content">
              <div class="main-color font-24 font-b" :style="!isSelect ? '' : 'text-align:center;'">Hi {{ $store.state.form.userInfo.firstName }}！</div>
              <div v-if="!isSelect">
                <div class="font-16 Medium text-content" style="color: #223b86" :class="{ 'font-20': $store.state.isPC }">
                  You can choose your favourite recipient here. Enter a key word and let the search engine filter the recipients e.g. RSPCA or Scouts or CFA. Scroll through the list and select your
                  chosen recipient, then <span style="color: #00a1de">set</span> by tapping the blue button below.
                </div>
                <div class="searchDropDown">
                  <div class="dropDown-box" @click.stop="showRecipient">
                    <img class="searchIcon" src="../assets/search.png" />
                    <ion-input style="height: 50px" class="Medium" type="text" @ionInput="dropdownSearch" v-model="recipient" placeholder="Search recipient (min 3 characters)" />
                  </div>
                  <div class="dropDownList" v-show="showRecipientList" ref="dropDownList" @scroll="scrollEvent">
                    <div class="dropDownItem Medium" v-for="(recipient, index) in recipientsList" :key="index" @click="chooseReceipent(recipient)">
                      {{ recipient }}
                    </div>
                  </div>
                </div>
                <div class="seleteReceipentBtn">
                  <ion-button :disabled="recipientsId == ''" expand="block" @click="confirmEvent">Confirm</ion-button>
                </div>
                <div class="receipentTips Medium" style="color: #666666">
                  Can’t find the recipient you would like to support, please email us at <u><a class="c666" href="mailto:loyalty@ritchies.com.au">loyalty@ritchies.com.au</a></u>
                </div>
                <div class="height50" style="height: 300px" v-if="!$store.state.isPC"></div>
              </div>
              <div class="receipentInfo" v-else>
                <div class="receipentName font-b">
                  {{ userRecipient.name }}
                </div>
                <p class="Medium">has received</p>
                <div class="receipentMoney font-b font-34">$ {{ userRecipient.recipientsTotal }}</div>
                <p class="Medium" style="margin-bottom: 25px; line-height: 22px">from Ritchies since program <br />inception</p>
                <p class="Medium">All recipients have received</p>
                <div class="allReceipentMoney font-b">$ {{ userRecipient.allTotal }}</div>
                <p class="Medium">from Ritchies since program inception</p>
                <div class="seleteReceipentBtn">
                  <ion-button expand="block" @click="isSelect = false">Choose different recipient</ion-button>
                </div>
                <div class="receipentTips Medium" style="color: #666666">
                  Can’t find the recipient you would like to support, please email us at <u><a class="c666" href="mailto:loyalty@ritchies.com.au">loyalty@ritchies.com.au</a></u>
                </div>
              </div>
            </div>
          </div>
          <div class="height50" v-if="$store.state.isPC"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonInput, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "communityDonation",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonInput, IonButton },
  data() {
    return {
      recipient: "",
      recipientsList: [],
      allRecipients: [],
      showRecipientList: false,
      isSelect: false,
      startRecipient: 0,
      endRecipient: 1000,
      userRecipient: {},
      recipientObj: {},
      recipientsId: "",
      inputTimeOut: null,
    };
  },
  computed: {
    userName() {
      const firstName = this.$store.state.form.userInfo.firstName;
      const lastName = this.$store.state.form.userInfo.lastName;
      if (firstName || lastName) {
        return firstName + " " + lastName;
      } else {
        return "";
      }
    },
  },
  methods: {
    dropdownSearch(e) {
      this.recipientsId = "";
      if (this.inputTimeOut) {
        clearTimeout(this.inputTimeOut);
      }
      this.inputTimeOut = setTimeout(() => {
        if (e.inputType == "insertCompositionText") {
          this.recipient = e.data;
        }

        this.recipient = this.recipient.replace(/[‘|’]/g, "'");

        if (this.recipient == "") {
          this.recipientsList = this.allRecipients.slice(0, 1000);
          this.$refs.dropDownList.scrollTo(0, 0);
          this.startRecipient = 0;
          this.endRecipient = 1000;
        } else if (this.recipient.length >= 3) {
          if (!this.showRecipientList) {
            this.showRecipient();
          }
          let searchResult = this.allRecipients.filter((item) => {
            return (
              item.toLowerCase().indexOf(this.recipient.toLowerCase()) >= 0
            );
          });
          this.recipientsList = searchResult;
        }
      }, 300);
    },

    showRecipient() {
      if (this.allRecipients.length == 0) {
        return false;
      }
      this.showRecipientList = true;
    },
    scrollEvent() {
      let scrollTop =
        Math.ceil(this.$refs.dropDownList.scrollTop) ||
        Math.ceil(this.$refs.dropDownList.pageYOffset) ||
        Math.ceil(this.$refs.dropDownList.scrollToTop);
      let offsetHeight = this.$refs.dropDownList.offsetHeight;
      let scrollHeight = this.$refs.dropDownList.scrollHeight;

      if (
        scrollTop + offsetHeight >= scrollHeight &&
        this.endRecipient < this.allRecipients.length &&
        !this.recipient
      ) {
        this.startRecipient = this.endRecipient;
        this.endRecipient = this.endRecipient + 1000;
        this.recipientsList = this.recipientsList.concat(
          this.allRecipients.slice(this.startRecipient, this.endRecipient)
        );
      }
    },
    chooseReceipent(recipient) {
      this.showRecipientList = false;
      this.recipient = recipient;
      for (let key in this.recipientObj) {
        if (this.recipientObj[key] == this.recipient) {
          this.recipientsId = key;
        }
      }
    },
    async confirmEvent() {
      this.$store.state.showLoading = true;
      let objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.saveUserRecipients;
      objdata.recipientsId = this.recipientsId;
      if (!objdata.recipientsId) {
        this.$store.commit("setwarning", {
          string: "Please select a recipient first.",
        });
        return;
      }
      const data = await this.$store.dispatch("apiFormEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.userRecipient = {
        name: this.recipient,
        recipientsId: objdata.recipientsId,
      };
      this.sendGAEvent("Click", "Community", "Confirm", this.recipient);

      this.recipient = "";
      this.getDonationReport();
      this.isSelect = true;
    },
    async getRecipientsList() {
      let objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.getRecipientsList;
      objdata.langCode = this.$store.state.lang;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      for (let key in data.data.result) {
        this.allRecipients.push(data.data.result[key]);
      }
      this.allRecipients.sort();
      this.recipientObj = data.data.result;
      this.recipientsList = this.allRecipients.slice(0, 1000);
    },
    async getUserRecipient() {
      let objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.getUserRecipients;
      objdata.langCode = this.$store.state.lang;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (data.data.result.recipientsId && data.data.result.exRecipientsId) {
        this.isSelect = true;
        this.userRecipient = data.data.result;
        this.getDonationReport();
      }
    },
    async getDonationReport() {
      let objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.getDonationReport;
      objdata.recipientsId = this.userRecipient.recipientsId;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.userRecipient.allTotal = Number(
        data.data.result.allTotal
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
      this.userRecipient.recipientsTotal = Number(
        data.data.result.recipientsTotal
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
      //   this.userRecipient.recipientsTotal = "6393.5";
    },
  },
  ionViewWillEnter() {
    this.getRecipientsList();
    this.$store.state.exitApp = false;
    this.getUserRecipient();
    this.sendGAPageView("Community");
  },
};
</script>
